<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-22 10:01:08
 * @FilePath: \qctsw-vue\packages\app\src\components\Rank\Cctv.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import { logicCctvNewsRank } from '@/logic/rank/cctvNews'

const {
  state,
  data,
  getRankData,
} = logicCctvNewsRank()

await getRankData()
</script>

<template>
  <n-card
    title="央视报道" embedded size="small"
    class="rounded-md"
    header-style="--n-title-font-weight: bold;"
  >
    <template #header-extra>
      <NuxtLink v-if="data && data.cctvCoopMediaId" :to="APP_ROUTER.newsMediaDetail(data.cctvCoopMediaId)">
        更多<i class="i-material-symbols:keyboard-double-arrow-right text-4" />
      </NuxtLink>
    </template>
    <UtilLoadState :state="state" :reload-handler="getRankData">
      <template v-if="data && data.cctvArticle.length">
        <NuxtLink
          v-for="(v, i) in data.cctvArticle" :key="i"
          class="mb-1 text-4 last:mb-0 line-clamp-1 hover:text-orange-6"
          :to="APP_ROUTER.newsDetail(v.id)"
        >
          {{ v.title }}
        </NuxtLink>
      </template>
      <BasicEmpty v-else />
    </UtilLoadState>
  </n-card>
</template>

<style lang='scss' scoped>
</style>

import { LOAD_STATE } from '@/shared/types'
import type { CctvNewsRankResponse } from '@/server/types'
import { getCctvNewsRankApi } from '@/server'

export function logicCctvNewsRank() {
  const state = ref(LOAD_STATE.INIT)
  const data = ref<CctvNewsRankResponse | null>(null)

  async function getRankData() {
    const res = await useLoadState({
      state,
      key: `CCTV`,
      onlyClient: true,
      fn: () => getCctvNewsRankApi(),
    })

    if (res && res.data)
      data.value = res.data
  }

  return {
    state,
    data,
    getRankData,
  }
}
